import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv } from 'uuid';
import clsx from 'clsx';
import {
  Box,
  Typography,
  Card,
  Divider,
  Button,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Loader from 'src/components/Loader';
import Alert from './Alert';
import { secondsToMinutes } from '../../utils';
import * as actions from '../../store/actions';
import { Modes, SuggestedRouteWarning, ViewModes } from '../../types';
import { MAX_LEGS } from '../../const';
import RouteDuration from './RouteDuration';
import StartPoint from './StartPoint';
import DestinationPoint from './DestinationPoint';
import CollapsedLegs from './CollapsedLegs';
import TransportLeg from './TransportLeg';
import WalkLeg from './WalkLeg';
import { useStyles } from './useStyles';

interface Props {
  getSuggestedRoutes: () => void;
  debouncedLoading: boolean;
  itineraries?: ItineraryType[];
  warning: SuggestedRouteWarning;
  resetWarning: () => void;
}

const SuggestedRoutes: FC<Props> = ({
  getSuggestedRoutes,
  debouncedLoading,
  itineraries,
  warning,
  resetWarning
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('trip');
  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const [activeRouteIndex, setActiveRouteIndex] = useState(0);

  const handleCardClick = (itinerary: ItineraryType, index: number) => {
    !isDesktopScreen && showRouteDetails(itinerary);
    setActiveRouteIndex(index);
  };

  const showRouteDetails = useCallback(
    (payload: ItineraryType) => {
      dispatch(actions.setSelectedRoute(payload));
      dispatch(actions.setViewMode(ViewModes.ROUTE_DETAILS_VIEW));
    },
    [dispatch]
  );

  return (
    <Box px={2} py={1} className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        height={36}
        className={classes.headerWrapper}
      >
        <Typography
          variant="button"
          color="textPrimary"
          className={classes.heading}
        >
          {t('suggestedRoutes')}
        </Typography>
        {itineraries && (
          <IconButton
            onClick={getSuggestedRoutes}
            className={classes.refreshWrapper}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      {debouncedLoading && (
        <Box
          display="flex"
          height={200}
          width="100%"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          <Loader />
        </Box>
      )}
      {warning && !debouncedLoading && (
        <Box mt={0.5}>
          {' '}
          <Alert title={t(warning)} onClose={resetWarning} />
        </Box>
      )}
      {itineraries &&
        !debouncedLoading &&
        warning !== 'noRoutesBetweenPointsWarning' &&
        // @ts-ignore
        itineraries.map((itinerary: ItineraryType, routeIndex: number) => {
          let aggregatedTransfers = 0;
          let aggregatedMinWalk = 0;
          let transportIndex = 0;

          return (
            <Card
              key={uuidv()}
              className={clsx(
                classes.route,
                routeIndex === activeRouteIndex && classes.active
              )}
              onClick={() => handleCardClick(itinerary, routeIndex)}
            >
              <RouteDuration
                duration={itinerary.duration}
                walkTime={itinerary.walkTime}
              />
              <Box
                height={30}
                key={uuidv()}
                mx={1}
                my={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="relative"
              >
                <StartPoint startTime={itinerary.startTime} />
                {itinerary.legs.map((leg: LegType, index: number) => {
                  const isWalk = leg.mode === Modes.WALK;
                  const isLegsCollapsed = itinerary.legs.length > MAX_LEGS;

                  if (isLegsCollapsed && index > 1) {
                    if (isWalk) {
                      aggregatedMinWalk += secondsToMinutes(leg.duration);
                    } else {
                      aggregatedTransfers += 1;
                    }

                    return null;
                  }

                  if (!isWalk) {
                    transportIndex += 1;
                  }

                  return isWalk ? (
                    <WalkLeg key={uuidv()} {...leg} />
                  ) : (
                    <TransportLeg
                      key={uuidv()}
                      index={transportIndex}
                      {...leg}
                    />
                  );
                })}
                <CollapsedLegs
                  transfers={aggregatedTransfers}
                  walk={aggregatedMinWalk}
                />
                <DestinationPoint endTime={itinerary.endTime} />
              </Box>
              <Divider className={classes.divider} />
              {isDesktopScreen && (
                <Button
                  className={clsx(
                    classes.detailsBtn,
                    routeIndex === activeRouteIndex && classes.active
                  )}
                  onClick={() => showRouteDetails(itinerary)}
                >
                  {t('details')}
                </Button>
              )}
            </Card>
          );
        })}
    </Box>
  );
};

export default SuggestedRoutes;
