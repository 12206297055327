import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { LANGS } from 'src/constants';
import { abbrMap } from './constants';

const cyrillicToTranslit = new CyrillicToTranslit({ preset: 'uk' });

export const makeTransliteration = (lang: string) => (name: string) => {
  if (lang === LANGS.UK) {
    return name;
  }

  const reg = new RegExp(Object.keys(abbrMap).join('|'), 'gi');

  const newName = name?.replace(
    reg,
    matched => abbrMap[matched.toLowerCase()] || matched
  );

  return cyrillicToTranslit.transform(newName);
};
