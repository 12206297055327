import {
  useRef,
  useEffect,
  lazy,
  Suspense,
  useCallback,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Loader from 'src/components/Loader';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { selectIsFavoritesShown } from 'src/views/TripSettingsView/store/selectors';
import { selectIsAuthenticated } from 'src/store/selectors';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import useLoadingDebounce from 'src/hooks/useLoadingDebounce';
import moment from 'moment';
import { RouteOptions as RouteOptionValues } from 'src/types/enums';
import useLazyQueryError from 'src/hooks/useLazyQueryError';
import * as selectors from '../store/selectors';
import RouteSearchFields from './RouteSearchFields';
import RouteOptions from './RouteOptions';
import SuggestedRoutes from './SuggestedRoutes';
import FavoriteRoutesList from './Favorites/FavoriteRoutesList';
import {
  SuggestedRouteWarning,
  TimeSettingsMode,
  UserAddressType
} from '../types';
import {
  DATE_FORMAT,
  GET_ROUTES_QUERY,
  MAX_WALK_DISTANCE,
  TIME_FORMAT
} from '../const';
import * as actions from '../store/actions';
import { mapItineraries, showWarning } from '../utils';

const SuggestedRouteDetails = lazy(() => import('./SuggestedRouteDetails'));
const UserAddressSearchField = lazy(() => import('./UserAddressSearchField'));
const StopDetails = lazy(() => import('./StopDetails'));

export const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  leftBarWrapper: {
    width: 375,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${spacing(6)}px)`,
    margin: spacing(3),
    position: 'absolute',
    zIndex: 1001,
    '&$fullHeightWrapper': {
      [breakpoints.up('sm')]: {
        height: `calc(100% - ${spacing(6)}px)`
      }
    },
    '&$fullScreenWrapper': {
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.background.dark}`,
      borderRadius: 16,
      [breakpoints.up('sm')]: {
        height: `calc(100% - ${spacing(6)}px)`
      }
    },
    [breakpoints.down('xs')]: {
      width: `calc(100% - ${spacing(1)}px)`,
      maxHeight: `calc(100% - ${spacing(1)}px)`,
      margin: spacing(0.5),
      '&$fullScreenWrapper': {
        maxHeight: `calc(50% - ${spacing(1)}px)`,
        bottom: 0
      }
    }
  },
  leftBar: {
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    backgroundColor: palette.background.default,
    marginTop: spacing(0.5)
  },
  searchWrapper: {
    borderRadius: 16,
    backgroundColor: palette.background.default
  },
  suggestedRoutesWrapper: {
    marginTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    backgroundColor: palette.common.white
  },
  fullScreenWrapper: {},
  fullHeightWrapper: {}
}));

const SearchBar = () => {
  const classes = useStyles();
  const prevActiveUserAddress = useRef<UserAddressType | null>(null);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const isRouteDetailsShown = useSelector(selectors.selectIsRouteDetailsShown);
  const isSearchShown = useSelector(selectors.selectIsSearchShown);
  const isFavoritesShown = useSelector(selectIsFavoritesShown);
  const isUserAddressShown = useSelector(selectors.selectIsUserAddressShown);
  const isStopDetailsShown = useSelector(selectors.selectIsStopDetailsShown);
  const isSuggestedRoutesShown = useSelector(
    selectors.selectIsSuggestedRoutesShown
  );
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const activeUserAddress = useSelector(selectors.selectActiveUserAddress);
  const startPoint = useSelector(selectors.selectStartPoint);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const routeOption = useSelector(selectors.selectRouteOption);
  const timeSettings = useSelector(selectors.selectTimeSettings);

  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const isRouteOptionsVisible = isDesktopScreen || isSuggestedRoutesShown;

  useEffect(() => {
    prevActiveUserAddress.current = activeUserAddress;
  }, [activeUserAddress]);

  const [getSuggestedRoutes, { data, loading, error }] = useLazyQuery(
    GET_ROUTES_QUERY,
    {
      fetchPolicy: 'network-only',
      context: { headers: { 'Accept-Language': i18n.language } }
    }
  );

  const debouncedLoading = useLoadingDebounce(loading);

  const [itineraries, setItineraries] = useState<ItineraryType[] | undefined>(
    mapItineraries(routeOption, timeSettings, data?.plan?.itineraries)
  );
  const [shownWarning, setShownWarning] = useState<SuggestedRouteWarning>(null);
  const [activeRouteIndex, setActiveRouteIndex] = useState(0);

  const isSamePoints =
    startPoint?.lat === destinationPoint?.lat &&
    startPoint?.lng === destinationPoint?.lng;

  const handleGetSuggestedRoutes = useCallback(() => {
    if (startPoint && destinationPoint && !isSamePoints) {
      if (timeSettings.mode === TimeSettingsMode.DEPART_NOW) {
        dispatch(
          actions.setTimeSetting({
            mode: TimeSettingsMode.DEPART_NOW,
            date: moment().format(DATE_FORMAT),
            time: moment().format(TIME_FORMAT)
          })
        );
      }
      getSuggestedRoutes({
        variables: {
          fromPlace: `${startPoint.lat},${startPoint.lng}`,
          toPlace: `${destinationPoint.lat},${destinationPoint.lng}`,
          date: timeSettings.date,
          time: timeSettings.time,
          maxWalkDistance: MAX_WALK_DISTANCE,
          numItineraries: 10,
          wheelchair: routeOption === RouteOptionValues.LOW_FLOOR,
          arriveBy: timeSettings.mode === TimeSettingsMode.ARRIVE_BY
        },
        context: { headers: { 'Accept-Language': i18n.language } }
      });
    } else {
      setItineraries([]);
      setShownWarning('noRoutesBetweenPointsWarning');
    }
  }, [
    startPoint,
    destinationPoint,
    isSamePoints,
    timeSettings.mode,
    timeSettings.date,
    timeSettings.time,
    getSuggestedRoutes,
    routeOption,
    i18n.language,
    dispatch
  ]);

  useLazyQueryError(error);

  useEffect(() => {
    setActiveRouteIndex(0);
    setItineraries(
      mapItineraries(routeOption, timeSettings, data?.plan?.itineraries)
    );
  }, [data, i18n.language, routeOption, timeSettings]);

  useEffect(() => {
    handleGetSuggestedRoutes();
  }, [handleGetSuggestedRoutes]);

  useEffect(() => {
    if (itineraries && !isSamePoints) {
      setShownWarning(
        showWarning(
          moment(`${timeSettings?.date} ${timeSettings?.time}`),
          itineraries,
          timeSettings.mode === TimeSettingsMode.ARRIVE_BY
        )
      );
      dispatch(actions.setSelectedRoute(itineraries[activeRouteIndex]));
    } else {
      dispatch(actions.setSelectedRoute(null));
    }
  }, [itineraries, activeRouteIndex, timeSettings, isSamePoints, dispatch]);

  const resetWarning = () => setShownWarning(null);

  return (
    <Box
      className={clsx(
        classes.leftBarWrapper,
        isSuggestedRoutesShown && classes.fullHeightWrapper,
        (isRouteDetailsShown || isStopDetailsShown) && classes.fullScreenWrapper
      )}
    >
      {isSearchShown && (
        <>
          <Box className={classes.searchWrapper} boxShadow={2}>
            <RouteSearchFields
              autofocus={!!prevActiveUserAddress.current && !activeUserAddress}
            />
            {isRouteOptionsVisible && <RouteOptions />}
          </Box>

          <Box
            className={clsx(
              classes.leftBar,
              isSuggestedRoutesShown && classes.suggestedRoutesWrapper
            )}
            boxShadow={2}
          >
            {isAuthenticated && isFavoritesShown && <FavoriteRoutesList />}
            {isSuggestedRoutesShown && (
              <SuggestedRoutes
                getSuggestedRoutes={handleGetSuggestedRoutes}
                debouncedLoading={debouncedLoading}
                itineraries={itineraries}
                warning={shownWarning}
                resetWarning={resetWarning}
              />
            )}
          </Box>
        </>
      )}
      <Suspense
        fallback={
          <Box className={classes.loaderWrapper}>
            <Loader />
          </Box>
        }
      >
        {isRouteDetailsShown && <SuggestedRouteDetails />}
        {isUserAddressShown && isAuthenticated && <UserAddressSearchField />}
        {isStopDetailsShown && <StopDetails />}
      </Suspense>
    </Box>
  );
};

export default SearchBar;
